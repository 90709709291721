import React, { useState } from "react";
// import { FaGoogle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./../style/LoginModal.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate(); // For navigation

  const validateForm = () => {
    const validationErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      validationErrors.email = "Email is required.";
    } else if (!emailRegex.test(email)) {
      validationErrors.email = "Invalid email format.";
    }

    if (!password) {
      validationErrors.password = "Password is required.";
    }

    return validationErrors;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate form
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error("Please fix the errors before submitting.");
      return;
    }

    setErrors({}); // Clear errors if validation passes

    // Call the API
    try {
      const response = await fetch("https://biz.dropyourbusiness.com/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          pass: password,
        }),
      });

      if (!response.ok) {
        throw new Error("Login failed. Please check your credentials.");
      }

      const data = await response.json();
      toast.success("Login successful!");
      localStorage.setItem("user", JSON.stringify(data)); // Store user in localStorage
      navigate("/");

      // Emit custom event for Header update
      window.dispatchEvent(new Event("user-login"));

      console.log("Login response:", data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  
  

  return (
    <div className="login-page">
      <div className="login-container">
        <h2 className="modal-title">
          Log <span className="highlight">In</span>
        </h2>

        <div className="input-group">
          <label>Email</label>
          <div className="input-container">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {errors.email && <p className="error-message">{errors.email}</p>}
        </div>

        <div className="input-group">
          <label>Password</label>
          <div className="input-container">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {errors.password && <p className="error-message">{errors.password}</p>}
        </div>

        <button type="submit" className="login-button" onClick={handleLogin}>
          Login
        </button>

        <p className="or-text">Or Login With</p>
        {/* <div className="social-login">
          <img src="/images/google.png" alt="" onClick={handleGoogleLogin}></img>
        </div> */}

        <p className="footer-text">
          Haven't Account? <Link to="/register">Sign Up</Link> &nbsp;&nbsp;|&nbsp;&nbsp;{" "}
          <Link to="/">Forget Password?</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
