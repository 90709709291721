/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaSignInAlt } from "react-icons/fa";
import { PiSignOutBold } from "react-icons/pi";
import { FiPlusCircle, FiUser } from "react-icons/fi";
import "./../style/header.css";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [hasBusinessId, setHasBusinessId] = useState(false);

  const loadUserFromStorage = () => {
    const storedUser = localStorage.getItem("user");
    const storedBusinessId = localStorage.getItem("businessId");
    
    if (storedUser) {
      try {
        const parsedData = JSON.parse(storedUser); // Parse the JSON string
        if (parsedData.user) {
          setUser(parsedData.user); // Extract and set the user object
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
      }
    } else {
      setUser(null);
      setIsLoggedIn(false);
    }

    // Check for businessId
    setHasBusinessId(!!storedBusinessId);
  };

  useEffect(() => {
    loadUserFromStorage();

    // Listen for the custom "user-login" event
    const handleUserLogin = () => {
      loadUserFromStorage();
    };

    window.addEventListener("user-login", handleUserLogin);

    return () => {
      window.removeEventListener("user-login", handleUserLogin);
    };
  }, []);

  const handleSignOut = () => {
    localStorage.clear(); // Clear localStorage
    setUser(null); // Reset user state
    setIsLoggedIn(false); // Update state
    setHasBusinessId(false); // Reset businessId state
    navigate("/login"); // Redirect to login page
  };

  return (
    <header className="header">
      <div className="header-top">
        <div className="logo">
          <Link to="/">
            <img src="/images/logo1.png" alt="Logo" className="logo-image" />
          </Link>
        </div>
        <nav className="navigation">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li
              onClick={() => {
                navigate("/listings");
              }}
              style={{ cursor: "pointer" }}
            >
              {/* // eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>Listings</a>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/contact-us">Contacts</Link>
            </li>
          </ul>
        </nav>
        <div className="header-actions">
          {/* Sign In/Sign Out Button */}
          {isLoggedIn ? (
            <button className="sign-in" onClick={handleSignOut}>
              <PiSignOutBold /> Sign Out
            </button>
          ) : (
            <Link to="/login" className="sign-in">
              <FaSignInAlt /> Sign In
            </Link>
          )}

          {/* Conditionally render Add Listings or My Profile based on isListed */}
          {user && user.isListed ? (
            <button
              className="profile-icon"
              onClick={() => navigate("/business-listing")}
            >
              <FiUser /> My Profile
            </button>
          ) : (
            !hasBusinessId && (
              <button
                className="add-listing"
                onClick={() => {
                  if (isLoggedIn) {
                    navigate("/add-listing");
                  } else {
                    navigate("/login");
                  }
                }}
              >
                <FiPlusCircle /> Add Listings
              </button>
            )
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
