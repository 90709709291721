/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { MdOutlineLocationOn } from "react-icons/md";
import "./../style/topratedplaces.css";
import { useNavigate } from "react-router-dom";

const Listings = () => {
  const [businesses, setBusinesses] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(true);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  const navigate = useNavigate();

  const fetchBusinesses = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://biz.dropyourbusiness.com/api/business/all?page=${page}`
      );
      console.log("API Response:", response.data);

      // Update businesses and pagination data
      setBusinesses(response.data.businesses || []);
      setPagination({
        ...response.data.pagination,
        current_page: page,
      });
    } catch (error) {
      console.error("Error fetching business data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch the last page on initial load
    const fetchLastPage = async () => {
      try {
        const initialResponse = await axios.get(
          `https://biz.dropyourbusiness.com/api/business/all?page=1`
        );

        const lastPage = initialResponse.data.pagination?.last_page || 1;
        await fetchBusinesses(lastPage); // Fetch data for the last page
      } catch (error) {
        console.error("Error fetching initial pagination data:", error);
      }
    };

    fetchLastPage();
  }, []);

  const handlePageChange = (pageUrl) => {
    if (pageUrl) {
      const page = new URL(pageUrl).searchParams.get("page");
      fetchBusinesses(page);
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
        <p>Loading...</p>
      </div>
    );
  }

  if (!businesses.length) {
    return <div className="no-data">No businesses found.</div>;
  }
  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle the expanded state for the given business ID
    }));
  };

  return (
    <div className="top-rated-places">
      <h1>All</h1>
      <h2>
        Listing <span className="theme-cl">Businesses</span>
      </h2>

      <div className="card-main">
        <div className="places-grid">
          {businesses
            .slice()
            .reverse()
            .map((business) => (
              <div className="place-card" key={business.id}>
                <img
                  src={
                    business.images?.[0] || "https://via.placeholder.com/300"
                  }
                  alt={business.business_name}
                  className="place-image"
                  style={{ height: "300px", width: "350px" }}
                />
                <div className="Reveal-listing-price-info">
                  <span className="pricetag">{business.category}</span>
                </div>
                <div
                  className={`status-${
                    business.status === "A" ? "open" : "closed"
                  }`}
                >
                  {business.status === "A" ? "Now Open" : "Closed"}
                </div>
                <h3>{business.business_name}</h3>
                {/* Description with "Read More" toggle */}
                <p
                  className="description"
                  style={{ color: "#55667b", fontSize: "15px" }}
                >
                  {expandedDescriptions[business.id]
                    ? business.description
                    : `${business.description.slice(0, 100)}...`}
                  {business.description.length > 100 && (
                    <button
                      onClick={() => toggleDescription(business.id)}
                      className="read-more-button-listing"
                    >
                      {expandedDescriptions[business.id]
                        ? "Read Less"
                        : "Read More"}
                    </button>
                  )}
                </p>
                <p
                  style={{
                    color: "#55667b",
                    fontSize: "13px",
                    borderTop: "1px solid #e3e3ea",
                    marginTop: "15px",
                    paddingTop: "20px",
                  }}
                >
                  <MdOutlineLocationOn /> {business.address.street_address_1},{" "}
                  {business.address.city}, {business.address.state}
                </p>
                <div className="card-footer">
                  <div className="user-info">
                    <span className="user-name">
                      Owner: {business.owner_name}
                    </span>
                  </div>
                  <div
                    className="view-button-main"
                    style={{ marginTop: "10px" }}
                  >
                    <button
                      className="view-button"
                      onClick={() => navigate(`/business/${business.id}`)}
                    >
                      View More
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange(pagination.next_page_url)}
          disabled={!pagination.next_page_url}
        >
          Previous
        </button>
        <span className="pagination-info">
          Page {pagination.last_page + 1 - pagination.current_page} of{" "}
          {pagination.last_page}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange(pagination.prev_page_url)}
          disabled={!pagination.prev_page_url}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Listings;
