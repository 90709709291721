import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AboutUs from "./pages/AboutUs";
import Register from "./pages/Register";
import Login from "./pages/Login";
import AddListing from "./pages/AddListing";
import BusinessListing from "./pages/BusinessListing";
import Listings from "./pages/Listings";
import BusinessDetail from "./components/BusinessDetail";

const App = () => {
  return (
    <Router>
      <div>
   
      <Header/>
        {/* Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/add-listing" element={<AddListing />} />
          <Route path="/business-listing" element={<BusinessListing/>} />
          <Route path="/listings" element={<Listings/>} />
          <Route path="/business/:id" element={<BusinessDetail />} />
        </Routes>
      </div>

      <Footer/>
    </Router>
  );
};

export default App;
