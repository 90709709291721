import React from "react";
import "./../style/topratedplaces.css";
import { MdOutlineLocationOn, MdOutlineAddIcCall } from "react-icons/md";

const TopRatedPlaces = () => {
  const places = [
    {
      id: 1,
      title: "Shree Satyam Burger House",
      category: "Hotel",
      image: "https://themezhub.net/reveal-live/reveal/assets/img/l-15.jpg", // Replace with actual image URLs
      priceRange: "$45 - $70",
      status: "Now Open",
      location: "2014 Bastin Drive, USA",
      phone: "91 548 548 7549",
      reviews: 42,
      rating: 4.5,
      user: "Daniel Preet",
      daysAgo: "3 Days Ago",
    },
    {
      id: 2,
      title: "Mangalam Wedding Planner",
      category: "Wedding",
      image: "https://themezhub.net/reveal-live/reveal/assets/img/l-15.jpg",
      priceRange: "$45 - $70",
      status: "Closed",
      location: "3244 Coulter Lane, Canada",
      phone: "91 548 548 7549",
      reviews: 42,
      rating: 4.0,
      user: "Reema Preet",
      daysAgo: "4 Days Ago",
    },
    {
      id: 3,
      title: "Shruthi Spa & Massage",
      category: "Massage",
      image: "https://themezhub.net/reveal-live/reveal/assets/img/l-15.jpg",
      priceRange: "$45 - $70",
      status: "Now Open",
      location: "611 Meadow Drive, London",
      phone: "91 548 548 7549",
      reviews: 42,
      rating: 4.8,
      user: "Daniel Joe",
      daysAgo: "1 Day Ago",
    },
    {
        id: 4,
        title: "Netshafe Pizza House",
        category: "Food",
        image: "https://themezhub.net/reveal-live/reveal/assets/img/l-4.jpg",
        priceRange: "$45 - $70",
        status: "Now Open",
        location: "611 Meadow Drive, London",
        phone: "91 548 548 7549",
        reviews: 42,
        rating: 4.8,
        user: "Daniel Joe",
        daysAgo: "1 Day Ago",
      },
      {
        id: 5,
        title: "Netshafe Pizza House",
        category: "Food",
        image: "https://themezhub.net/reveal-live/reveal/assets/img/l-4.jpg",
        priceRange: "$45 - $70",
        status: "Now Open",
        location: "611 Meadow Drive, London",
        phone: "91 548 548 7549",
        reviews: 42,
        rating: 4.8,
        user: "Daniel Joe",
        daysAgo: "1 Day Ago",
      },
      {
        id: 6,
        title: "Netshafe Pizza House",
        category: "Food",
        image: "https://themezhub.net/reveal-live/reveal/assets/img/l-4.jpg",
        priceRange: "$45 - $70",
        status: "Now Open",
        location: "611 Meadow Drive, London",
        phone: "91 548 548 7549",
        reviews: 42,
        rating: 4.8,
        user: "Daniel Joe",
        daysAgo: "1 Day Ago",
      },
  ];

  return (
    <div className="top-rated-places"style={{marginTop:"-10%"}}>
        <h1>Featured</h1>
      <h2>Top Rated <span className="theme-cl">Places</span></h2>
      <div className="card-main">
      <div className="places-grid">
        {places.map((place) => (
          <div className="place-card" key={place.id}>
            <img src={place.image} alt={place.title} className="place-image" />
            <div className="Reveal-listing-price-info">
              <span className="pricetag">{place.priceRange}</span>
            </div>
            {place.status === "Now Open" && (
              <div className="status-open">Now Open</div>
            )}
            {place.status === "Closed" && (
              <div className="status-closed">Closed</div>
            )}
            <div className="lup-category">
              <p>{place.category}</p>
            </div>
            <h3>{place.title}</h3>
            <div className="rating">
              {"⭐".repeat(Math.round(place.rating))} 
              <span className="overall-reviews">({place.reviews} Reviews)</span>
            </div>
            <p>
              <MdOutlineLocationOn />
              {place.location}
            </p>
            <p>
              <MdOutlineAddIcCall />
              {place.phone}
            </p>
            <div className="card-footer">
              <div className="user-info">
                <img
                  src="https://themezhub.net/reveal-live/reveal/assets/img/user-1.png"
                  alt={place.user}
                  className="user-avatar"
                />
                <div className="user-days-name">
                <span className="user-name">{place.user}</span>
                <span className="user-days">{place.daysAgo}</span>
                </div>
              </div>
              <div className="view-button-main">
                <button className="view-button">View</button>{" "}
              </div>
            </div>{" "}
          </div>
        ))}
      </div>
     </div>
    </div>
  );
};

export default TopRatedPlaces;
