import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../style/Businesslisting.css";

const BusinessListing = () => {
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [isUploadingBackground, setIsUploadingBackground] = useState(false);
  const getUserFromStorage = () => {
    try {
      const storedUser = localStorage.getItem("user");
      const localbusinessId = localStorage.getItem("localbusinessId"); // Get localbusinessId
      console.log("localbusinessId-->", localbusinessId);
  
      if (!storedUser && !localbusinessId) return null; // Return null if neither is found
  
      const parsedUser = storedUser ? JSON.parse(storedUser) : {};
      return {
        user: parsedUser.user || null,
        localbusinessId: localbusinessId || null, // Include localbusinessId in the returned object
      };
    } catch (error) {
      console.error("Error parsing user data from localStorage:", error);
      return null;
    }
  };
  
  const userDetails = getUserFromStorage();
  const businessId = userDetails?.user?.businessId || userDetails?.localbusinessId; // Use either businessId or localbusinessId
  
  useEffect(() => {
    const fetchBusinessDetails = async () => {
      if (!businessId) {
        toast.error("Business ID not found in user data.");
        setLoading(false);
        return;
      }
  
      try {
        const response = await axios.get(
          `https://biz.dropyourbusiness.com/api/business/${businessId}`
        );
        setBusiness(response.data.business);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching business details:", error);
        toast.error("Failed to fetch business details.");
        setLoading(false);
      }
    };
  
    fetchBusinessDetails();
  }, [businessId]);
  
  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("logo", file);

    try {
      setIsUploadingLogo(true);
      const response = await axios.post(
        `https://biz.dropyourbusiness.com/api/business/${businessId}/logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const cleanedLogoUrl = response.data.logo.replace(/\\/g, "");
      setBusiness((prev) => ({
        ...prev,
        logo: cleanedLogoUrl || prev.logo,
      }));
      setIsUploadingLogo(false);
    } catch (err) {
      console.error("Error uploading logo:", err);
      setIsUploadingLogo(false);
    }
  };

  const handleBackgroundImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("images[]", file);

    try {
      setIsUploadingBackground(true);
      const response = await axios.post(
        `https://biz.dropyourbusiness.com/api/business/${businessId}/images`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const rawImageUrl = response.data.images[0];
      const cleanedImageUrl = rawImageUrl.replace(/\\/g, "");

      setBusiness((prev) => ({
        ...prev,
        images: [cleanedImageUrl, ...prev.images],
      }));

      setIsUploadingBackground(false);
    } catch (err) {
      console.error("Error uploading background image:", err);
      setIsUploadingBackground(false);
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
        <p>Loading...</p>
      </div>
    );
  }

  if (!business) {
    return <div>No business details availablesssssss  .</div>;
  }

  const {
    business_name,
    owner_name,
    category,
    sub_category,
    description,
    logo,
    images,
    address,
    social_accounts,
    status,
    business_time,
  } = business;

  const backgroundImage =
    images?.length > 0 ? images[0] : "/fallback-image.png";

  return (
    <div className="business-listing-page">
      <h1>Business Listing Details</h1>

      {status === "P" ? (
        <div className="approval-message">
          <p>
            Your business is under review. Once approved by the admin, it will
            be listed here.
          </p>
        </div>
          ) : status === "R" ? (
            <div className="rejection-message">
              <p>
                Your business has been rejected by the admin. Please contact our
                support team for further assistance.
              </p>
            </div>
      ) : (
        <div className="business-listing">
          <div className="overlay">
            <div
              className="profile-section"
              style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "#fff",
              }}
            >
              <div className="logo-container">
                <img
                  src={logo}
                  alt={`${business_name} Logo`}
                  className="profile-logo"
                />
                <label htmlFor="upload-logo" className="upload-icon">
                  <span>+</span>
                </label>
                <input
                  type="file"
                  id="upload-logo"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleLogoUpload}
                />
              </div>
              {isUploadingLogo && <p>Uploading logo...</p>}
              <div className="homebanner-title">
                <div className="business-name">
                  <h2>{business_name}</h2>
                </div>
                <div className="business-title-below">
                  <p className="category">
                    {category} - {sub_category}
                  </p>
                  <label
                    htmlFor="upload-background"
                    className="upload-background"
                  >
                    <span>+</span>
                  </label>
                </div>
              </div>
              <input
                type="file"
                id="upload-background"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleBackgroundImageUpload}
              />
              {isUploadingBackground && <p>Uploading background image...</p>}
            </div>
          </div>

          <div className="description-box">
            <h3>Description</h3>
            <p className="description">{description}</p>
          </div>

          <div className="details-section">
            <div className="contact-box">
              <h2>Contact Information</h2>
              <p>
                <strong>Owner:</strong> {owner_name}
              </p>
              <p>
                <strong>Email:</strong> {address?.email}
              </p>
              <p>
                <strong>Contact:</strong> {address?.contact_1}
              </p>
              <p>
                <strong>Address:</strong>{" "}
                {`${address.street_address_1}, ${address.city}, ${address.state}, ${address.country}, ${address.pincode}`}
              </p>
            </div>

            <div className="social-box">
              <h2>Social Accounts</h2>
              <ul>
                {social_accounts?.facebook_url && (
                  <li>
                    <a
                      href={social_accounts.facebook_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                )}
                {social_accounts?.instagram_url && (
                  <li>
                    <a href={social_accounts.instagram_url}>Instagram</a>
                  </li>
                )}
                {social_accounts.linkedin_url && (
                  <li>
                    <a href={social_accounts.linkedin_url}>LinkedIn</a>
                  </li>
                )}
                {social_accounts.x_url && (
                  <li>
                    <a href={social_accounts.x_url}>x_url</a>
                  </li>
                )}
                {social_accounts.youtube_url && (
                  <li>
                    <a href={social_accounts.youtube_url}>YouTube</a>
                  </li>
                )}
                {social_accounts.pinterest_url && (
                  <li>
                    <a href={social_accounts.pinterest_url}>Pinterest</a>
                  </li>
                )}
              </ul>
            </div>

            <div className="business-box">
              <h2>Business Timing</h2>
              {business_time.is_24_7 ? (
                <p>Open 24/7</p>
              ) : (
                <p>Working Hours: {business_time.working_hours}</p>
              )}
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default BusinessListing;
