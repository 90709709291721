import React, { useState } from "react";
import "./../style/register.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { auth, googleProvider } from "./../components/firebase";
import { signInWithPopup } from "firebase/auth";
import emailjs from "emailjs-com"; // Import Email.js

const Register = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!formData.password) {
      newErrors.password = "Password is required.";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
    }

    if (!formData.confirmPassword) {

      newErrors.confirmPassword = "Confirm Password is required.";
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendWelcomeEmail = async (toEmail) => {
    try {
      const templateParams = {
        to_name: toEmail.split("@")[0], // Extract the username from the email
        to_email: toEmail,
      };

      await emailjs.send(
        "service_vphssg1", // Replace with your Email.js service ID
        "template_pe7ikei", // Replace with your Email.js template ID
        templateParams,
        "mukVylKGf9712x24Z" // Replace with your Email.js public key
      );

      console.log("Welcome email sent successfully!");
    } catch (error) {
      console.error("Error sending welcome email:", error);
    }
  };

  const handleRegister = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error("Please fix the errors before submitting.");
      return;
    }

    setErrors({}); // Clear errors if validation passes

    try {
      const response = await fetch("https://biz.dropyourbusiness.com/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formData.email,
          pass: formData.password,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to register. Please try again.");
      }

      const data = await response.json();
      console.log("dataregister-->",data)
      toast.success("Registration successful!");

      // Send Welcome Email
      await sendWelcomeEmail(formData.email);

      // Reset form fields
      setFormData({ email: "", password: "", confirmPassword: "" });
      navigate("/login");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      // Authenticate with Firebase
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Register the user via API
      const registerResponse = await fetch("https://biz.dropyourbusiness.com/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.email,
          pass: "google-auth",
        }),
      });

      if (!registerResponse.ok && registerResponse.status !== 409) {
        throw new Error("Registration failed. Please try again.");
      }

      // Send Welcome Email
      await sendWelcomeEmail(user.email);

      toast.success("Google login successful!");
      navigate("/");
    } catch (error) {
      toast.error("Google login failed. Please try again.");
      console.error("Google login error:", error);
    }
  };

  return (
    <div className="register-container">
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="register-box">
        <div className="register-header">
          <div className="register-icon">
            <img src="/images/Big.jpg" alt="Register Icon" />
          </div>
        </div>
        <div className="register-form">
          <div className="form-group">
            <label htmlFor="email">Your Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
            />
            {errors.password && <p className="error-message">{errors.password}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
            {errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>}
          </div>
          <div className="form-footer">
            <p>
              Have An Account? <a href="/login">Login Here</a>
            </p>
            <button type="button" className="register-btn" onClick={handleRegister}>
              Register
            </button>
          </div>
        </div>
        <div className="social-login">
          <p>Or login via</p>
          <div className="social-icons">
            <img src="/images/google.png" alt="" onClick={handleGoogleLogin} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
