// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAInzX7LJrHTD0ey26j831USs1oSRksHHg",
  authDomain: "drop-your-business.firebaseapp.com",
  projectId: "drop-your-business",
  storageBucket: "drop-your-business.firebasestorage.app",
  messagingSenderId: "350064246596",
  appId: "1:350064246596:web:029c9e598d889a48622111",
  measurementId: "G-5PZVPK1K73",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
