import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./../style/addlisting.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterest , FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const AddListing = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [countries, setCountries] = useState([]); // State to store countries
  const [states, setStates] = useState([]); // State to store states
  const [cities, setCities] = useState([]); // State to store cities
  const [formData, setFormData] = useState({
    login_id: null,
    business_name: "",
    owner_name: "",
    category: "",
    sub_category: "",
    description: "",
    logo: null,
    images: [],
    address: {
      email: "",
      contact_1: "",
      contact_2: "",
      url: "",
      street_address_1: "",
      street_address_2: "",
      landmark: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    },
    social_accounts: {
      facebook_url: "",
      instagram_url: "",
      x_url: "",
      linkedin_url: "",
      youtube_url: "",
      pinterest_url: "",
    },
    business_time: {
      is_24_7: false,
      working_hours: {
        Monday: { open: "", close: "" },
        Tuesday: { open: "", close: "" },
        Wednesday: { open: "", close: "" },
        Thursday: { open: "", close: "" },
        Friday: { open: "", close: "" },
        Saturday: { open: "", close: "" },
        Sunday: { open: "", close: "" },
      },
    },
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  console.log(successMessage);

  // Fetch categories on mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://biz.dropyourbusiness.com/api/categories"
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("Failed to load categories. Please try again.");
      }
    };

    fetchCategories();
  }, []);

  // Fetch countries on mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://biz.dropyourbusiness.com/api/countries"
        );
        setCountries(
          Array.isArray(response.data?.data) ? response.data.data : []
        );
      } catch (error) {
        console.error("Error fetching countries:", error);
        toast.error("Failed to load countries.");
      }
    };

    fetchCountries();
  }, []);

  // Fetch states based on selected country
  const fetchStates = async (countryId) => {
    try {
      console.log("Fetching states for countryId:", countryId);
      const response = await axios.get(
        `https://biz.dropyourbusiness.com/api/states/${countryId}`
      );
      console.log("Fetched states response:", response.data);

      // Extracting the "data" field and setting the states
      setStates(Array.isArray(response.data?.data) ? response.data.data : []);
    } catch (error) {
      console.error("Error fetching states:", error);
      setStates([]); // Fallback to empty array
    }
  };

  // Fetch cities based on selected state
  const fetchCities = async (stateId) => {
    try {
      console.log("Fetching cities for stateId:", stateId);
      const response = await axios.get(
        `https://biz.dropyourbusiness.com/api/cities/${stateId}`
      );
      console.log("Fetched cities response:", response.data);
      setCities(Array.isArray(response.data?.data) ? response.data.data : []);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setCities([]);
    }
  };

  // Fetch subcategories based on selected category
  const fetchSubcategories = async (categoryId) => {
    try {
      const response = await axios.get(
        `https://biz.dropyourbusiness.com/api/categories/${categoryId}/subcategories`
      );
      setSubcategories(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      setSubcategories([]);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    const [parent, child] = name.split(".");
    if (child) {
      setFormData((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // Fetch subcategories on category change
    if (name === "category") {
      setFormData((prev) => ({
        ...prev,
        sub_category: "", // Clear selected subcategory
      }));
      fetchSubcategories(value);
    }

    // Fetch states on country change
    if (name === "address.country") {
      setFormData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          state: "",
          city: "",
        },
      }));
      fetchStates(value);
    }

    // Fetch cities on state change
    if (name === "address.state") {
      setFormData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          city: "",
        },
      }));
      fetchCities(value);
    }
  };

  // const handleFileUpload = (e, type) => {
  //   const files = e.target.files;
  //   if (type === "logo") {
  //     setFormData((prev) => ({
  //       ...prev,
  //       logo: files[0],
  //     }));
  //   } else if (type === "images") {
  //     setFormData((prev) => ({
  //       ...prev,
  //       images: Array.from(files),
  //     }));
  //   }
  // };

  // const handleCheckboxChange = (e) => {
  //   const { checked } = e.target;
  //   setFormData((prev) => ({
  //     ...prev,
  //     business_time: {
  //       ...prev.business_time,
  //       is_24_7: checked,
  //     },
  //   }));
  // };

  // const handleWorkingHoursChange = (day, field, value) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     business_time: {
  //       ...prev.business_time,
  //       working_hours: {
  //         ...prev.business_time.working_hours,
  //         [day]: {
  //           ...prev.business_time.working_hours[day],
  //           [field]: value,
  //         },
  //       },
  //     },
  //   }));
  // };

  useEffect(() => {
    const storedData = localStorage.getItem("user");
    console.log("Raw data from localStorage:", storedData);

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData); // Parse the JSON
        console.log("Parsed data:", parsedData);
        console.log("dvsgdyhsdg", parsedData.user?.id);
        // Access the `user` object if the structure matches
        if (parsedData.user?.id) {
          // console.log(user.id,"loginidddd")
          setFormData((prev) => ({
            ...prev,
            login_id: parsedData.user.id, // Use the nested user.id
          }));
        } else {
          console.error("User ID not found in the stored data.");
        }
      } catch (error) {
        console.error("Error parsing stored data:", error);
      }
    } else {
      console.error("No data found in localStorage.");
    }
  }, []);

  // eslint-disable-next-line no-unused-vars
  const validateForm = () => {
    const requiredFields = {
      business_name: "Business Name is required",
      owner_name: "Owner Name is required",
      category: "Business Category is required",
      sub_category: "Sub Category is required",
      description: "Business Description is required",
      logo: "Business Logo is required",
      "address.email": "Email ID is required",
      "address.contact_1": "Contact No 1 is required",
      "address.street_address_1": "Street Address Line 1 is required",
      "address.country": "Country is required",
      "address.state": "State is required",
      "address.city": "City is required",
      "address.pincode": "Pincode is required",
    };
    // console.log("validateForm",validateForm,requiredFields)

    const newErrors = {};
    Object.entries(requiredFields).forEach(([field, message]) => {
      const [parent, child] = field.split(".");
      const value = child ? formData[parent]?.[child] : formData[field];
      if (!value) newErrors[field] = message;
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!validateForm()) {
    //   toast.error("Please fill all required fields.");
    //   return;
    // }

    const payload = {
      login_id: formData.login_id,
      business_name: formData.business_name,
      owner_name: formData.owner_name,
      category: formData.category,
      sub_category: formData.sub_category,
      description: formData.description,
      logo: "path/to/logo.png",
      images: formData.images.map((image) => "path/to/image.png"),
      address: formData.address,
      social_accounts: formData.social_accounts,
      business_time: formData.business_time,
    };

    try {
      const response = await axios.post(
        "https://biz.dropyourbusiness.com/api/business/add",
        JSON.stringify(payload),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log(response);
      setSuccessMessage("Business listing added successfully!");
      toast.success("Business added successfully!");
      navigate(`/business-listing`);

      if (response.data && response.data.business.id) {
        const businessId = response.data.business.id;
        console.log("businessId-->",businessId)
        toast.success("Business added successfully!");
        setSuccessMessage("Business listing added successfully!");
        localStorage.setItem("localbusinessId", businessId);
      }

      setFormData({
        login_id: null,
        business_name: "",
        owner_name: "",
        category: "",
        sub_category: "",
        description: "",
        logo: null,
        images: [],
        address: {
          email: "",
          contact_1: "",
          street_address_1: "",
          country: "",
          state: "",
          city: "",
          pincode: "",
        },
        social_accounts: {
          facebook_url: "",
          instagram_url: "",
          x_url: "",
          linkedin_url: "",
          youtube_url: "",
          pinterest_url: "",
        },
        business_time: {
          is_24_7: false,
          working_hours: {},
        },
      });
      setErrors({});
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      toast.error("Failed to add business. Please try again.");
    }
  };
  console.log("statesstatesstates", states);

  return (
    <div className="add-listing-page">
      <ToastContainer />
      <div className="addlisting-header">
        <h1>Add Business Listing</h1>
        <p>Submit your business details and grow your reach</p>
      </div>

      <form className="add-listing-form" onSubmit={handleSubmit}>
        <div className="form-section-general">
          <h2>Business Details</h2>
          <div className="form-group">
            <label htmlFor="business_name">Business Name*</label>
            <input
              type="text"
              id="business_name"
              name="business_name"
              value={formData.business_name}
              onChange={handleChange}
              placeholder="Enter business name"
            />
            {errors.business_name && (
              <p className="error-message">{errors.business_name}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="owner_name">Owner Name*</label>
            <input
              type="text"
              id="owner_name"
              name="owner_name"
              value={formData.owner_name}
              onChange={handleChange}
              placeholder="Enter owner name"
            />
            {errors.owner_name && (
              <p className="error-message">{errors.owner_name}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="category">Category*</label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.Category_Id} value={category.Category_Id}>
                  {category.Category_Name}
                </option>
              ))}
            </select>
            {errors.category && (
              <p className="error-message">{errors.category}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="sub_category">Sub Category*</label>
            <select
              id="sub_category"
              name="sub_category"
              value={formData.sub_category}
              onChange={handleChange}
              disabled={!subcategories.length}
            >
              <option value="">Select Subcategory</option>
              {subcategories.map((subcategory) => (
                <option
                  key={subcategory.Sub_Category_Id}
                  value={subcategory.Sub_Category_Id}
                >
                  {subcategory.Sub_Category_Name}
                </option>
              ))}
            </select>
            {errors.sub_category && (
              <p className="error-message">{errors.sub_category}</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="description">Description*</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Enter business description"
            ></textarea>
            {errors.description && (
              <p className="error-message">{errors.description}</p>
            )}
          </div>
        </div>

        <div className="form-section-address">
          <h2>Address Details</h2>
          <div className="form-group">
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              id="email"
              name="address.email"
              value={formData.address.email}
              onChange={handleChange}
              placeholder="Enter email"
            />
            {errors["address.email"] && (
              <p className="error-message">{errors["address.email"]}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="contact_1">Primary Contact*</label>
            <input
              type="text"
              id="contact_1"
              name="address.contact_1"
              value={formData.address.contact_1}
              onChange={handleChange}
              placeholder="Enter primary contact number"
            />
            {errors["address.contact_1"] && (
              <p className="error-message">{errors["address.contact_1"]}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="street_address_1">Street Address 1*</label>
            <input
              type="text"
              id="street_address_1"
              name="address.street_address_1"
              value={formData.address.street_address_1}
              onChange={handleChange}
              placeholder="Enter street address 1"
            />
            {errors["address.street_address_1"] && (
              <p className="error-message">
                {errors["address.street_address_1"]}
              </p>
            )}
          </div>

          <div className="form-group">
            <label>Country*</label>
            <select
              name="address.country"
              value={formData.address.country}
              onChange={handleChange}
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>

          {/* state field input  */}
          <div className="form-group">
            <label>State*</label>
            <select
              name="address.state"
              value={formData.address.state}
              onChange={handleChange}
              disabled={!states.length} // Disable if states array is empty
            >
              <option value="">Select State</option>
              {states.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </select>
            {errors["address.state"] && (
              <p className="error-message">{errors["address.state"]}</p>
            )}
          </div>

          {/* city dropdown */}

          <div className="form-group">
            <label>City*</label>
            <select
              name="address.city"
              value={formData.address.city}
              onChange={handleChange}
              disabled={!cities.length} // Disable if cities array is empty
            >
              <option value="">Select City</option>
              {cities.map((city) => (
                <option key={city.id} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
            {errors["address.city"] && (
              <p className="error-message">{errors["address.city"]}</p>
            )}
          </div>

          {/* Pincode input field */}
          <div className="form-group">
            <label htmlFor="pincode">Pincode*</label>
            <input
              type="text"
              id="pincode"
              name="address.pincode"
              value={formData.address.pincode}
              onChange={handleChange}
              placeholder="Enter pincode"
            />
            {errors["address.pincode"] && (
              <p className="error-message">{errors["address.pincode"]}</p>
            )}
          </div>
        </div>

        {/* <div className="form-section-images">
          <h2>Logo and Images</h2>
          <div className="form-group">
            <label htmlFor="logo">Upload Logo*</label>
            <input
              type="file"
              id="logo"
              onChange={(e) => handleFileUpload(e, "logo")}
            />
            {errors.logo && <p className="error-message">{errors.logo}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="images">Upload Images</label>
            <input
              type="file"
              id="images"
              multiple
              onChange={(e) => handleFileUpload(e, "images")}
            />
          </div>
        </div> */}

        <div className="form-section-social">
          <h2>Social Account</h2>
          <div className="social-accounts-grid">
            <div className="form-group">
              <label htmlFor="facebook_url">
              <FaFacebook style={{ marginRight: "8px", color: "#3b5998" }} />
                Facebook URL</label>
              <input
                type="url"
                id="facebook_url"
                name="social_accounts.facebook_url"
                value={formData.social_accounts.facebook_url}
                onChange={handleChange}
                placeholder="Enter Facebook URL"
              />
            </div>

            <div className="form-group">
              <label htmlFor="linkedin_url">  <FaLinkedin style={{ marginRight: "8px", color: "#0077B5" }} />LinkedIn URL</label>
              <input
                type="url"
                id="linkedin_url"
                name="social_accounts.linkedin_url"
                value={formData.social_accounts.linkedin_url}
                onChange={handleChange}
                placeholder="Enter LinkedIn URL"
              />
            </div>
            <div className="form-group">
              <label htmlFor="x_url"> <FaXTwitter  style={{ marginRight: "8px", color: "#000" }} />X URL</label>
              <input
                type="url"
                id="x_url"
                name="social_accounts.x_url"
                value={formData.social_accounts.x_url}
                onChange={handleChange}
                placeholder="Enter Twitter URL"
              />
            </div>
            <div className="form-group">
              <label htmlFor="instagram_url">  <FaInstagram style={{ marginRight: "8px", color: "#E4405F" }} />Instagram URL</label>
              <input
                type="url"
                id="instagram_url"
                name="social_accounts.instagram_url"
                value={formData.social_accounts.instagram_url}
                onChange={handleChange}
                placeholder="Enter Instagram URL"
              />
            </div>

            <div className="form-group">
              <label htmlFor="pinterest_url"> <FaPinterest style={{ marginRight: "8px", color: "#BD081C" }} />Pinterest URL</label>
              <input
                type="url"
                id="pinterest_url"
                name="social_accounts.pinterest_url"
                value={formData.social_accounts.pinterest_url}
                onChange={handleChange}
                placeholder="Enter Pinterest URL"
              />
            </div>

            <div className="form-group">
              <label htmlFor="envato_url"><FaYoutube style={{ marginRight: "8px", color: "#FF0000" }} />  Youtube URL</label>
              <input
                type="url"
                id="youtube_url"
                name="social_accounts.youtube_url"
                value={formData.social_accounts.youtube_url}
                onChange={handleChange}
                placeholder="Youtube URL"
              />
            </div>
          </div>
        </div>

        <div className="form-section-hours">
          <h2>Business Hours</h2>
          <div className="form-group custom-checkbox">
            <label>
              <input
                type="checkbox"
                checked={formData.business_time.is_24_7}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    business_time: {
                      ...prev.business_time,
                      is_24_7: e.target.checked,
                    },
                  }))
                }
              />
              Open 24/7
            </label>
          </div>
          {!formData.business_time.is_24_7 &&
            Object.keys(formData.business_time.working_hours).map((day) => (
              <div className="form-group" key={day}>
                <label>{day}</label>
                <div>
                  <input
                    type="time"
                    value={formData.business_time.working_hours[day].open}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        business_time: {
                          ...prev.business_time,
                          working_hours: {
                            ...prev.business_time.working_hours,
                            [day]: {
                              ...prev.business_time.working_hours[day],
                              open: e.target.value,
                            },
                          },
                        },
                      }))
                    }
                  />
                  <input
                    type="time"
                    value={formData.business_time.working_hours[day].close}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        business_time: {
                          ...prev.business_time,
                          working_hours: {
                            ...prev.business_time.working_hours,
                            [day]: {
                              ...prev.business_time.working_hours[day],
                              close: e.target.value,
                            },
                          },
                        },
                      }))
                    }
                  />
                </div>
              </div>
            ))}
        </div>

        <button type="submit" className="submit-button">
          Submit Listing
        </button>
      </form>
    </div>
  );
};

export default AddListing;
