import React, { useEffect, useState } from "react";
import "./../style/searchbar.css";
import { FaSearch, FaList } from "react-icons/fa";
import { MdLocationSearching } from "react-icons/md";

const SearchBar = () => {
  const [categories, setCategories] = useState([]); // State to store categories
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetch categories from the API
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://biz.dropyourbusiness.com/api/categories"
        );
        const data = await response.json();
        setCategories(data); // Update state with the fetched categories
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setIsLoading(false); // Set loading to false
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="searchbar-main">
      <div className="big-header-capt">
        <h1>Discover Your City</h1>
      </div>
      <div className="search-bar">
        <div className="search-field">
          <FaSearch className="icon" />
          <input type="text" placeholder="Keywords..." />
        </div>
        <div className="location-field">
          <MdLocationSearching className="icon" />
          <input type="text" placeholder="Location..." />
        </div>
        <div className="category-field">
          <FaList className="icon" />
          <select>
            <option>Choose Category</option>
            {isLoading ? (
              <option>Loading...</option>
            ) : (
              categories.map((category) => (
                <option key={category.Category_Id} value={category.Category_Id}>
                  {category.Category_Name}
                </option>
              ))
            )}
          </select>
        </div>
        <button className="search-button">Search</button>
      </div>
      <ul className="categories-list">
        <li className="category-item">Hotel & Spa</li>
        <li className="category-item">Education</li>
        <li className="category-item">Shopping</li>
        <li className="category-item">Restaurants</li>
      </ul>
    </div>
  );
};

export default SearchBar;
