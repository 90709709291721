/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./../style/footer.css";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
        <div className="logo">
          <img src="/images/logo1.png" alt="Logo" className="logo-image" />
        </div>
          <p>407-472 Rue Saint-Sulpice, Montreal</p>
          <p>Quebec, H2Y 2V8</p>
          <div className="social-links">
            <a href="#" aria-label="Facebook">
              <FaFacebookF />
            </a>
            <a href="#" aria-label="Twitter">
              <FaTwitter />
            </a>
            <a href="#" aria-label="Instagram">
              <FaInstagram />
            </a>
            <a href="#" aria-label="LinkedIn">
              <FaLinkedinIn />
            </a>
          </div>
        </div>

        <div className="footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li>
              <a href="#">About Us</a>
            </li>
            <li>
              <a href="#">FAQs Page</a>
            </li>
            <li>
              <a href="#">Checkout</a>
            </li>
            <li>
              <a href="#">Login</a>
            </li>
          </ul>
        </div>

        <div className="footer-links">
          <h4>Developers</h4>
          <ul>
            <li>
              <a href="#">Booking</a>
            </li>
            <li>
              <a href="#">Stays</a>
            </li>
            <li>
              <a href="#">Adventures</a>
            </li>
            <li>
              <a href="#">Author Detail</a>
            </li>
          </ul>
        </div>

        <div className="footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li>
              <a href="#">Support</a>
            </li>
            <li>
              <a href="#">About Us</a>
            </li>
            <li>
              <a href="#">Privacy & Terms</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          Copyright © 2024 Drop-Your-Business | Designed and developed by HJ
          Sysweb{" "}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
