import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./../style/businessdetail.css";

const BusinessDetail = () => {
  const { id } = useParams(); // Get the business ID from the URL
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        const response = await axios.get(
          `https://biz.dropyourbusiness.com/api/business/${id}`
        );
        setBusiness(response.data.business);
      } catch (error) {
        console.error("Error fetching business details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessDetails();
  }, [id]);

  if (loading) {
    return <div className="loader-container">
    <div className="loader"></div>
    <p>Loading...</p>
  </div>;
  }

  if (!business) {
    return <div className="no-data">Business not found.</div>;
  }

  const {
    business_name,
    owner_name,
    category,
    sub_category,
    description,
    logo,
    images,
    // is_active,
    address,
    social_accounts,
    business_time,
  } = business;

  return (
    <div className="business-detail-page">
      <div className="business-header">
        <img
          src={logo || "https://via.placeholder.com/150"}
          alt={business_name}
          className="business-logo"
        />
        <h1>{business_name}</h1>
        <p>
          <strong>Category:</strong> {category}
          {sub_category && ` - ${sub_category}`}
        </p>
        <p> <strong>Owner Name: </strong>{owner_name}</p>
 
      </div>

      <div className="business-description">
        <h2>About the Business</h2>
        <p>{description}</p>
      </div>

      <div className="business-gallery">
        <h2>Gallery</h2>
        {images.length ? (
          <div className="gallery-grid">
            {images.map((image, index) => (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                key={index}
                src={image}
                alt={`${business_name} Image ${index + 1}`}
                className="gallery-image"
              />
            ))}
          </div>
        ) : (
          <p>No images available.</p>
        )}
      </div>

      <div className="business-contact">
        <h2>Contact Information</h2>
        <p>
          <strong>Email:</strong> {address.email}
        </p>
        <p>
          <strong>Phone:</strong> {address.contact_1}
        </p>
        {address.contact_2 && <p>{address.contact_2}</p>}
        <p>
          <strong>Address:</strong> {address.street_address_1}
          {address.street_address_2 && `, ${address.street_address_2}`}
        </p>
        <p>
          {address.city}, {address.state}, {address.country} -{" "}
          {address.pincode}
        </p>
        {address.url && (
          <p>
            <strong>Website:</strong>{" "}
            <a href={address.url} target="_blank" rel="noopener noreferrer">
              {address.url}
            </a>
          </p>
        )}
      </div>

      <div className="business-social">
        <h2>Social Media</h2>
        <ul>
          {social_accounts.facebook_url && (
            <li>
              <a href={social_accounts.facebook_url} target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </li>
          )}
          {social_accounts.instagram_url && (
            <li>
              <a href={social_accounts.instagram_url} target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
            </li>
          )}
          {social_accounts.linkedin_url && (
            <li>
              <a href={social_accounts.linkedin_url} target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
            </li>
          )}
          {social_accounts.youtube_url && (
            <li>
              <a href={social_accounts.youtube_url} target="_blank" rel="noopener noreferrer">
                YouTube
              </a>
            </li>
          )}
        </ul>
      </div>

      <div className="business-timing">
        <h2>Business Hours</h2>
        {business_time.is_24_7 ? (
          <p>Open 24/7</p>
        ) : (
          <ul>
            {Object.entries(business_time.working_hours).map(
              ([day, hours], index) => (
                <li key={index}>
                  <strong>{day.charAt(0).toUpperCase() + day.slice(1)}:</strong>{" "}
                  {hours}
                </li>
              )
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default BusinessDetail;
